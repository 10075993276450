// Login - Base
// -------------------------

.login-page {
    height: 100%;
    background-color: $ls-color-gray--very-light;

    .login-wrapper {
        height: 100%;
        padding-top: 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-box {
        color: $white;
        padding: 20px;
        width: 100%;
        max-width: 400px;

        .form-control {
            // margin-top: 20px;
        }

        .input-label {
            padding-bottom: 5px;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #55547A
        }

        .other-actions {
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .page-copyright {
            font-size: 14px;
            margin-top: 50px;
            text-align: center;
            p {
                margin-bottom: 10px;
            }
        }

        a {
            color: $ls-color-primary;

            &:hover {
                color: darken($ls-color-primary, 5%);
            }
        }

        .btn-login {
            background-color: $ls-color-primary;
            color: white;

            &:hover {
                background-color: darken($ls-color-primary, 5%);
            }
        }
    }

    .login-box .c-input {
        color: #fff;

        .c-indicator {
            top: 2px;
        }
    }

    .login-wrapper .c-input {
        > input {
            &:checked ~ .c-indicator {
                background-color: #333;
                @include box-shadow(none);
            }

            &:focus ~ .c-indicator {
                // the mixin is not used here to make sure there is feedback
                box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem darken(#333, 5%);
            }

            &:active ~ .c-indicator {
                background-color: darken(#333, 5%);
                @include box-shadow(none);
            }
        }
    }

    .logo-main {
        margin-top: 100px;
        margin-bottom: 30px;
        text-align: left;

        img {
            height: auto;
            // width:90%;
           
        }
    }

    .other-options {
        border-top: 1px solid #fff;
        padding: 1rem 0;
        padding-top: 2rem;

        h6 {
            color: #c7cbd1;
            font-size: 15px;
            margin-top: 12px;
        }

        a,
        a:hover {
            color: $white;
        }
    }

    .actions {
        margin-top: 30px;
        .btn-theme {
            margin-right: 20px;
            margin-top: 20px;
        }
    }

    @media (max-width: $small-breakpoint) {
        .other-actions {
            font-size: 14px;
        }

        .logo-main {
            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .social-links {
        margin-top: 40px;

        .link-text {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
            color: $ls-color-gray
        }

        .social-logo {
            display: flex;
            align-items: center;
            padding-top: 25px;

            .icon {
                display: block;
                margin-right: 30px;
                cursor: pointer;
            }
        }
    }
}
